import * as React from 'react';
import {
    CREATE_CHANNEL,
    DELETE_PROPERTY_BILL,
    GET_CHANNEL_AUTOMATION_RATIO,
    GET_CUSTOMER_AUTOMATION_RATIO,
    GET_TABLE_OVERVIEW_DATA,
    GET_CLIENT_TABLE_FILTER_OPTIONS,
    RE_UPLOAD_FILE,
    RETRY_STEP,
    UPLOAD_FILE,
    GET_CHANNELS_OVERVIEW_DATA,
} from '../../queries';
import DocumentOverview from '../../../generic_document/pages/Overview';
import * as constants from '../../constants';
import { useTranslation } from 'react-i18next';
import { DocumentType } from '../../../generic_document/constants';
import ExternalStatusFilter from './ExternalStatusFilter';

const Overview = (props) => {
    const { t } = useTranslation('assistance');

    const documentConfiguration = {
        documentType: DocumentType.PropertyBill,
        documentTypeName: 'propertyBill',
        activeTab: 'property-bill',
        constants: constants,
        //Path accessible for generic_document overview page
        demoAutomationRatePath: require('../../../generic_document/pages/Overview/demoAutomationRate.json'),
        CREATE_CHANNEL: CREATE_CHANNEL,
        DELETE_RECORD: DELETE_PROPERTY_BILL,
        GET_CHANNEL_AUTOMATION_RATIO: GET_CHANNEL_AUTOMATION_RATIO,
        GET_CUSTOMER_AUTOMATION_RATIO: GET_CUSTOMER_AUTOMATION_RATIO,
        GET_CHANNELS_OVERVIEW_DATA: GET_CHANNELS_OVERVIEW_DATA,
        GET_TABLE_OVERVIEW_DATA: GET_TABLE_OVERVIEW_DATA,
        GET_CLIENT_TABLE_FILTER_OPTIONS: GET_CLIENT_TABLE_FILTER_OPTIONS,
        RETRY_STEP: RETRY_STEP,
        UPLOAD_FILE: UPLOAD_FILE,
        RE_UPLOAD_FILE: RE_UPLOAD_FILE,
        renderExtraFilters: ({ filters, onFiltersChange }) => (
            <>
                <ExternalStatusFilter filters={filters} onFiltersChange={onFiltersChange} />
            </>
        ),
    };

    return DocumentOverview({ documentConfiguration, props });
};

export default Overview;
