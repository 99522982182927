import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { isoDateFormat } from '../../../../utils/dates';
import DateRangeFilter from '../../../../analytics/components/DateRangeFilter';
import { camelCase } from 'lodash';
import * as React from 'react';

const DateFilter = ({ filters, onFiltersChange, filterName }) => {
    const { t } = useTranslation('assistance');

    const fromValue = filters.find((f) => f.name === filterName && f.expression === 'ge')?.value?.[0];
    const toValue = filters.find((f) => f.name === filterName && f.expression === 'le')?.value?.[0];

    const dateRange = {
        from: moment(fromValue, isoDateFormat).isValid() ? moment(fromValue, isoDateFormat).toDate() : null,
        to: moment(toValue, isoDateFormat).isValid() ? moment(toValue, isoDateFormat).toDate() : null,
    };

    const today = moment();
    const dateRangeOptions = [
        {
            label: t('overview.filters.createdAt.options.today'),
            value: [today.format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.yesterday'),
            value: [
                today.clone().subtract(1, 'day').format(isoDateFormat),
                today.clone().subtract(1, 'day').format(isoDateFormat),
            ].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastWeek'),
            value: [today.clone().subtract(1, 'week').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastMonth'),
            value: [today.clone().subtract(1, 'month').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.last3Months'),
            value: [today.clone().subtract(90, 'days').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.last6Months'),
            value: [today.clone().subtract(6, 'months').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastYear'),
            value: [today.clone().subtract(1, 'year').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
    ];

    const handleDateChange = (range) => {
        const updatedFilters = filters.filter((f) => f.name !== filterName);

        if (range.from) {
            updatedFilters.push({
                name: filterName,
                value: [moment(range.from).format(isoDateFormat)],
                expression: 'ge',
                exclude: false,
            });
        }

        if (range.to) {
            updatedFilters.push({
                name: filterName,
                value: [moment(range.to).format(isoDateFormat)],
                expression: 'le',
                exclude: false,
            });
        }

        onFiltersChange(updatedFilters);
    };

    return (
        <DateRangeFilter
            options={dateRangeOptions}
            label={t(`overview.filters.${camelCase(filterName)}.label`)}
            range={dateRange}
            onRangeChange={handleDateChange}
        />
    );
};

export default DateFilter;
