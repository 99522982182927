import React, { useEffect, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classnames from '../../../core_updated/utils/classnames';
import { withIcon } from '../../../core_updated/components/Icon';
import { faCheck, faCircleNotch, faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import Field, { FieldProps } from '../../../core_updated/components/Fields/Field';
import { mergeRefs } from '../../../core_updated/utils/mergeRefs';

export interface Option {
    label: string;
    value: string;
    role?: string;
    description?: string;
    disabled?: boolean;
    [key: string]: any;
}

interface SelectProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'value' | 'defaultValue'>,
        FieldProps {
    options: Option[];
    renderOption?: (option: Option, value: string) => React.ReactNode;
    emptyMessage?: string;
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    readOnly?: boolean;
}

const ClearIcon = withIcon(faXmark);
const CheckIcon = withIcon(faCheck);
const ChevronDownIcon = withIcon(faChevronDown);
const LoadingCircleIcon = withIcon(faCircleNotch);

const defaultRenderOption = (option: Option) => (
    <span>
        <span className="text-primary">{option.label}</span>
        {option.label !== option.value ? <span className="block text-xs text-tertiary">{option.value}</span> : null}
        {option.description ? <span className="block text-xs text-tertiary">{option.description}</span> : null}
    </span>
);

const NonModalMultiSelectField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onFocus,
            onBlur,
            readOnly,
            disabled,
            controls,
            className,
            inputRef = undefined,
            options = [],
            renderOption = defaultRenderOption,
            isLoading = false,
            ...props
        }: SelectProps,
        ref: any
    ) => {
        const arrayToString = (s) => {
            return JSON.stringify(s);
        };
        const stringToArray = (s) => {
            return JSON.parse(s);
        };

        const triggerRef = React.useRef<HTMLButtonElement>(null);
        // values that comes from props are always in the form of a JSON array: '["a", "b", "c"]'
        const [stringValues, setStringValues] = useControllableState(defaultValue, propsValue, onValueChange);
        // convert
        const [values, setValues] = useState(stringToArray(stringValues));
        const selectedOptions = options.filter((option) => values.includes(option.value));
        useEffect(() => {
            setStringValues(arrayToString(values));
        }, [values]);
        const handleOpenChange = (open: boolean) => {
            if (!open) onBlur?.(null);
            else onFocus?.(null);
        };

        const handleClear = () => {
            setValues([]);
            // simulate pointer down event to open dropdown - update is saved when dropdown is closed
            triggerRef.current?.dispatchEvent(
                new PointerEvent('pointerdown', {
                    bubbles: true,
                    cancelable: true,
                    button: 0,
                })
            );
        };

        return (
            <DropdownMenu.Root onOpenChange={handleOpenChange} modal={false}>
                <Field
                    className={classnames('shadow-sm group', className)}
                    readOnly={readOnly}
                    disabled={disabled}
                    ref={ref}
                >
                    <Field.Input>
                        <DropdownMenu.Trigger
                            className="px-2 py-1.5 flex-1 min-w-0 justify-start items-center flex bg-transparent text-left min-h-8 relative gap-2 flex-wrap"
                            disabled={disabled || readOnly}
                            {...props}
                            ref={mergeRefs(inputRef, triggerRef)}
                        >
                            {values && values.length > 0 ? (
                                <div className="flex gap-2 flex-wrap">
                                    {selectedOptions.map((option) => (
                                        <span
                                            key={option.value}
                                            className="flex items-center bg-secondary text-primary px-2 py-1 rounded-full text-sm border border-primary shadow-sm"
                                        >
                                            {option.label}
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering dropdown
                                                    e.preventDefault();
                                                    setValues(values.filter((v) => v !== option.value));
                                                }}
                                                className="ml-2 text-sm text-danger hover:text-danger-dark focus:outline-none"
                                                aria-label={`Remove ${option.label}`}
                                            >
                                                <ClearIcon className="h-4 w-4 text-current" />
                                            </button>
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                <span className="text-tertiary">{props.placeholder}</span>
                            )}
                        </DropdownMenu.Trigger>
                    </Field.Input>
                    <Field.Controls>
                        {!readOnly && (
                            <Field.ControlButton className="group-hover:opacity-100 point !bg-transparent">
                                {isLoading ? (
                                    <LoadingCircleIcon spin />
                                ) : (
                                    <ChevronDownIcon
                                        onClick={() => {
                                            // trigger on pointer down on trigger ref
                                            if (triggerRef.current) {
                                                triggerRef.current.dispatchEvent(
                                                    new PointerEvent('pointerdown', { bubbles: true })
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Field.ControlButton>
                        )}
                        {!readOnly && values && (
                            <Field.ControlButton onClick={handleClear}>
                                <ClearIcon />
                            </Field.ControlButton>
                        )}
                        {controls}
                    </Field.Controls>
                </Field>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className="p-1 z-[20000] w-full max-h-96 overflow-hidden rounded-md border bg-primary shadow-md outline-none animate-in fade-in-0 zoom-in-95"
                        align="start"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="max-h-[300px] overflow-auto">
                            {options.map((option: Option, i: number) => (
                                <DropdownMenu.Item
                                    key={i}
                                    onSelect={() => {
                                        if (!option.disabled) {
                                            if (!values.includes(option.value)) {
                                                setValues([...values, option.value]);
                                            }
                                        }
                                    }}
                                    disabled={option.disabled}
                                    className={classnames(
                                        'flex items-center gap-2 w-full',
                                        'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-4 text-sm outline-none hover:bg-secondary focus:bg-secondary',
                                        option.disabled && 'opacity-50 pointer-events-none',
                                        values.includes(option.value) && 'bg-brand text-neutral-900'
                                    )}
                                >
                                    {renderOption(option, values)}
                                    {values.includes(option.value) && (
                                        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center text-primary">
                                            <CheckIcon className="h-4 w-4" />
                                        </span>
                                    )}
                                </DropdownMenu.Item>
                            ))}
                        </div>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        );
    }
);

export default NonModalMultiSelectField;
