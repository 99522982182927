import React, { useEffect } from 'react';

import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classnames from '../../../core_updated/utils/classnames';
import { withIcon } from '../../../core_updated/components/Icon';
import {
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faBold,
    faItalic,
    faListOl,
    faListUl,
    faRedo,
    faStrikethrough,
    faUnderline,
    faUndo,
} from '@fortawesome/pro-regular-svg-icons';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import Field from '../../../core_updated/components/Fields/Field';
import { TextAlign } from '@tiptap/extension-text-align';

const BoldIcon = withIcon(faBold);

const TextAlignLeftIcon = withIcon(faAlignLeft);
const TextAlignCenterIcon = withIcon(faAlignCenter);
const TextAlignRightIcon = withIcon(faAlignRight);
const ItalicIcon = withIcon(faItalic);
const ListIcon = withIcon(faListUl);
const ListOrderedIcon = withIcon(faListOl);
const RedoIcon = withIcon(faRedo);
const StrikethroughIcon = withIcon(faStrikethrough);
const UnderlineIcon = withIcon(faUnderline);
const UndoIcon = withIcon(faUndo);

const MenuBarButton = ({
    active,
    disabled,
    ...props
}: React.ComponentPropsWithRef<'button'> & { active?: boolean }) => {
    return (
        <button
            {...props}
            type="button"
            disabled={disabled}
            className={classnames(
                'flex items-center justify-center w-8 h-8 text-sm font-medium rounded focus:outline-none focus:ring focus:ring-brand',
                active && 'bg-brand text-brand border-brand border-solid border',
                disabled && 'text-tertiary',
                active && disabled && 'bg-tertiary'
            )}
        />
    );
};

const MenuBarSeparator = () => {
    return <div className="border-r border-solid border-primary h-6 mx-2" />;
};

const MenuBar = ({ editor, disabled }: any) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="flex gap-0.5 flex-wrap items-center">
            <MenuBarButton
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={disabled || !editor.can().chain().focus().toggleBold().run()}
                active={editor.isActive('bold')}
            >
                <BoldIcon />
            </MenuBarButton>
            <MenuBarButton
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={disabled || !editor.can().chain().focus().toggleItalic().run()}
                active={editor.isActive('italic')}
            >
                <ItalicIcon />
            </MenuBarButton>
            <MenuBarButton
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={disabled || !editor.can().chain().focus().toggleUnderline().run()}
                active={editor.isActive('underline')}
            >
                <UnderlineIcon />
            </MenuBarButton>
            <MenuBarButton
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={disabled || !editor.can().chain().focus().toggleStrike().run()}
                active={editor.isActive('strike')}
            >
                <StrikethroughIcon />
            </MenuBarButton>

            <MenuBarSeparator />

            <MenuBarButton
                onClick={() =>
                    !editor.isActive({ textAlign: 'left' })
                        ? editor.chain().focus().setTextAlign('left').run()
                        : editor.chain().focus().unsetTextAlign().run()
                }
                active={editor.isActive({ textAlign: 'left' })}
                disabled={disabled}
            >
                <TextAlignLeftIcon />
            </MenuBarButton>

            <MenuBarButton
                onClick={() =>
                    !editor.isActive({ textAlign: 'center' })
                        ? editor.chain().focus().setTextAlign('center').run()
                        : editor.chain().focus().unsetTextAlign().run()
                }
                active={editor.isActive({ textAlign: 'center' })}
                disabled={disabled}
            >
                <TextAlignCenterIcon />
            </MenuBarButton>

            <MenuBarButton
                onClick={() =>
                    !editor.isActive({ textAlign: 'right' })
                        ? editor.chain().focus().setTextAlign('right').run()
                        : editor.chain().focus().unsetTextAlign().run()
                }
                active={editor.isActive({ textAlign: 'right' })}
                disabled={disabled}
            >
                <TextAlignRightIcon />
            </MenuBarButton>

            <MenuBarSeparator />

            <MenuBarButton
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                active={editor.isActive('bulletList')}
                disabled={disabled}
            >
                <ListIcon />
            </MenuBarButton>
            <MenuBarButton
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                active={editor.isActive('orderedList')}
                disabled={disabled}
            >
                <ListOrderedIcon />
            </MenuBarButton>

            <MenuBarSeparator />

            <MenuBarButton
                onClick={() => editor.chain().focus().undo().run()}
                disabled={disabled || !editor.can().chain().focus().undo().run()}
            >
                <UndoIcon />
            </MenuBarButton>
            <MenuBarButton
                onClick={() => editor.chain().focus().redo().run()}
                disabled={disabled || !editor.can().chain().focus().redo().run()}
            >
                <RedoIcon />
            </MenuBarButton>
        </div>
    );
};

const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    // @ts-ignore
    TextStyle.configure({ types: [ListItem.name] }),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false,
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false,
        },
    }),
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
    Underline,
];

export interface WysiwygProps extends React.ComponentPropsWithRef<'textarea'> {
    onValueChange?: (value: string) => void;
    controls?: React.ReactNode;
    widgetRef?: React.Ref<HTMLDivElement>;
}

const WysiwygField = ({
    defaultValue,
    value: propsValue,
    onValueChange,
    className,
    ref,
    readOnly,
    disabled,
    required,
    onChange,
    onBlur,
}: WysiwygProps) => {
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const editor = useEditor({
        extensions,
        content: value as Content,
        onUpdate: ({ editor }) => {
            setValue(editor.getHTML());
            // @ts-ignore
            onChange?.({ target: { value: editor.getHTML() } });
        },
        // @ts-ignore
        onBlur: ({ editor, event }) => onBlur?.(event),
        editorProps: {
            attributes: {
                class: 'formatted outline-none min-h-[10rem] px-3 py-2.5 flex-1 min-w-0 bg-transparent outline-none resize-y overflow-hidden',
            },
        },
        editable: !disabled,
    });

    useEffect(() => {
        // keep in sync with external changes
        if (editor && value !== editor.getHTML()) {
            editor.commands.setContent(value as Content);
        }
    }, [value, editor]);

    return (
        <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref} required={required}>
            <Field.Input>
                <div className="flex flex-col w-full flex-1 min-w-0">
                    <div className="bg-secondary-light outline-transparent p-1.5">
                        <MenuBar editor={editor} disabled={disabled} />
                    </div>
                    <EditorContent className="w-full flex-1 min-w-0" editor={editor} disabled={disabled} />
                </div>
            </Field.Input>
        </Field>
    );
};

export default WysiwygField;
