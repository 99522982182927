import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../../../core_updated/contexts/ApplicationContext';
import Filter, { defaultFormatLabel } from '../../../../analytics/components/Filter';
import * as React from 'react';
import { camelCase } from 'lodash';
import DropdownMenu from '../../../../core_updated/components/DropdownMenu';

const UserFilter = ({ filters, onFiltersChange, activeChannel, channels, filterName }) => {
    const { t } = useTranslation('assistance');

    // TODO: maybe move activeChannel to context
    const { user } = useApplicationContext();

    const filter = filters.find((f) => f.name === filterName);

    const selected = filter ? (Array.isArray(filter.value) ? filter.value : [filter.value]) : [];
    const handleSelectedChange = (selected: string[]) => {
        const newFilters = filters.filter((f) => f.name !== filterName);
        if (selected.length) {
            newFilters.push({ name: filterName, value: selected, exclude: false, expression: 'eq' });
        }
        onFiltersChange(newFilters);
    };

    // If there is no active channel, search in all of them
    const channelsToFilter = activeChannel ? [activeChannel] : channels;

    // Sort users by first name, but put the current user on top
    const allChannelUsers: any[] = channelsToFilter.flatMap((channel) =>
        channel.team?.users ? channel.team.users.filter((u) => u.email !== user.email) : []
    );
    const uniqueChannelUsers = Array.from(new Map(allChannelUsers.map((item) => [item.email, item])).values());

    uniqueChannelUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));
    uniqueChannelUsers.unshift(user);

    const userOptions = uniqueChannelUsers.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName}`,
    }));

    const WORKI_OPTION = { label: 'Worki (Automated)', value: 'automated' };

    const formatLabel = ({ defaultLabel, allLabel, options, selected, searchValue, multiple }) => {
        return defaultFormatLabel({
            defaultLabel,
            allLabel,
            options: [...options, WORKI_OPTION],
            selected,
            searchValue,
            multiple,
        });
    };

    const typeDisplay = camelCase(filterName);
    const extraContent =
        filterName == 'assisted_by' ? (
            <div className="fixed bottom-0 left-0 right-0 w-full p-2 border-t border-solid border-secondary bg-primary shadow-lg">
                <DropdownMenu.CheckboxItem
                    onCheckedChange={(checked) => {
                        if (checked) {
                            handleSelectedChange([...selected, WORKI_OPTION.value]);
                        } else {
                            handleSelectedChange(selected.filter((value) => value !== WORKI_OPTION.value));
                        }
                    }}
                    checked={selected.includes(WORKI_OPTION.value)}
                    onSelect={(e) => e.preventDefault()}
                    className="flex items-center space-x-2 cursor-pointer"
                >
                    <span>{WORKI_OPTION.label}</span>
                </DropdownMenu.CheckboxItem>
            </div>
        ) : null;

    return (
        <Filter
            allLabel={t(`overview.filters.${typeDisplay}.all`)}
            label={t(`overview.filters.${typeDisplay}.label`)}
            options={userOptions}
            selected={selected}
            onSelectedChange={handleSelectedChange}
            multiple
            showSearch={true}
            extraContent={extraContent}
            formatLabel={formatLabel}
        />
    );
};

export default UserFilter;
