import React from 'react';
import AutoCompleteField from '../../core_updated/components/Fields/AutoCompleteField';
import { useAssistanceFieldContext } from '../pages/Assistance/AssistanceFieldContext';
import { renderFrameworkContractOption } from './useLookup';
import { FieldProps, useFieldProps } from '../pages/Assistance/customizable/Field';
import useForceSelectionLookupProps from './useForceSelectionLookupProps';
import useReselectEventListener from '../pages/Assistance/useReselectEventListener';
import TextField from '../../core_updated/components/Fields/TextField';
import StringField from '../../core_updated/components/Fields/StringField';

export const FrameworkContractNumberField = (props: FieldProps) => {
    const { clientPartitionId, config } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: 'FrameworkContractLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
    });

    useReselectEventListener(props.inputRef, props.onReselect);

    return (
        <AutoCompleteField
            {...fieldProps}
            {...forceSelectionLookupProps}
            renderOption={renderFrameworkContractOption}
        />
    );
};

const FrameworkContractNameField = (props: FieldProps) => {
    const fieldProps = useFieldProps(props);

    return <StringField {...fieldProps} readOnly={true} disabled={true} />;
};

export const FrameworkContractNumberFieldConditionalComponents = [
    {
        condition: ({ fieldName }) => fieldName === 'framework_contract_number',
        component: FrameworkContractNumberField,
    },
    {
        condition: ({ fieldName }) => fieldName === 'framework_contract_name',
        component: FrameworkContractNameField,
    },
];
