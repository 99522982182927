import * as React from 'react';
import { useState } from 'react';
import Button from '../../core_updated/components/Button';
import classnames from '../../core_updated/utils/classnames';
import { withIcon } from '../../core_updated/components/Icon';
import {
    faArrowUpRightFromSquare,
    faChevronDown,
    faChevronUp,
    faEdit,
    faList,
    faThumbsDown,
    faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { useAssistanceContext } from '../../generic_document/pages/Assistance/AssistanceContext';
import { useListOfServicesAssistanceContext } from '../pages/ListOfServicesAssistance';
import { LIST_OF_SERVICE_ASSISTANCE_MODES } from './ListOfServicesAssistanceHeader';
import Switch from '../../core/components/Switch';

const NOT_RELEVANT_REASON_OTHER = 'OTHER';
const NOT_RELEVANT_REASONS = [
    'NOT_AN_LV_POSITION',
    'NO_PRODUCT',
    'MISSING_TEXT',
    'TOO_MUCH_TEXT',
    NOT_RELEVANT_REASON_OTHER,
];

const ThumbsUpIcon = withIcon(faThumbsUp);
const ThumbsDownIcon = withIcon(faThumbsDown);
const ListIcon = withIcon(faList);
const EditIcon = withIcon(faEdit);
const ExternalLinkIcon = withIcon(faArrowUpRightFromSquare);
const ChevronDownIcon = withIcon(faChevronDown);
const ChevronUpIcon = withIcon(faChevronUp);

interface NotRelevantBannerProps {
    reason?: string;
    onReasonChange?: (reason: string) => void;
}

const NotRelevantBanner = ({ reason }: NotRelevantBannerProps) => {
    const { t } = useTranslation('assistance');

    return (
        <div className="flex flex-col gap-3 bg-secondary px-5 py-4 rounded">
            <div className="text-sm text-secondary">{t('listOfServices.positionCard.notRelevantTitle')}</div>

            <div className="flex gap-2 items-center text-primary">
                <div className="text-sm">
                    {(reason && t(`listOfServices.positionCard.reasons.USER`, { username: reason })) ||
                        t(`listOfServices.positionCard.reasons.SYSTEM`)}
                </div>
            </div>
        </div>
    );
};

interface PositionAssistanceCardProps {
    className?: string;
    number: string;
    description: string;
    isRelevant?: boolean;
    onRelevantChange?: (isRelevant: boolean) => void;
    reason?: string;
    pageIndex?: number;
    articlesCount?: number;
    isIncluded?: boolean;
    onIncludedChange?: (isIncluded: boolean) => void;
    variant?: 'position' | 'pretext';
}

const PositionAssistanceCard = ({
    className,
    number,
    description,
    isRelevant,
    onRelevantChange,
    isIncluded,
    onIncludedChange,
    reason,
    pageIndex,
    variant = 'position',
}: PositionAssistanceCardProps) => {
    const { readOnly, loading, record } = useAssistanceContext();

    const { mode } = useListOfServicesAssistanceContext();

    const { t } = useTranslation('assistance');

    const [expanded, setExpanded] = useState(false);

    const handleRelevantChange = (isRelevant: boolean) => onRelevantChange?.(isRelevant);

    return (
        <div
            className={classnames(
                'flex flex-col rounded-lg border border-primary border-solid',
                variant === 'position' && 'bg-text-inverted shadow-md',
                variant === 'pretext' && 'bg-secondary',
                className
            )}
            data-scroll-anchor={isRelevant ? 'position' : undefined}
        >
            <div className="p-6 flex flex-col gap-2">
                <div className="flex justify-between text-sm">
                    <div className="font-semibold text-primary">
                        {variant === 'pretext'
                            ? t('listOfServices.positionCard.pretext')
                            : t('listOfServices.positionCard.position') + ' ' + number}
                    </div>
                    <a className="font-medium" href={`${record?.inputFile?.url}#page=${pageIndex}`} target="_blank">
                        {t('listOfServicesView.page')} {pageIndex}
                        <ExternalLinkIcon className={classnames('ml-2')} />
                    </a>
                </div>
                {/* 
                    text-sm has line-height: 1.25rem; / 20px
                    so max-h-[120px] cuts the description at 6 lines
                */}
                <div
                    className={classnames(
                        'text-sm overflow-hidden text-secondary whitespace-pre-line',
                        variant === 'pretext' && !expanded && 'max-h-[120px]'
                    )}
                >
                    {description}
                </div>
                {variant === 'pretext' && description?.split('\n').length > 6 && (
                    <div className="flex items-start text-sm font-medium text-link">
                        <button className="flex gap-2 rounded items-center" onClick={() => setExpanded(!expanded)}>
                            {expanded
                                ? t('listOfServices.positionCard.showLess')
                                : t('listOfServices.positionCard.showMore')}
                            {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </button>
                    </div>
                )}
            </div>

            {variant === 'position' && (
                <>
                    <div className="border-b border-primary border-solid" />

                    <div className="flex flex-col gap-4 py-4 px-6">
                        <div className="flex gap-2 justify-between items-center">
                            <div className="flex gap-2">
                                <Button
                                    disabled={readOnly || loading}
                                    variant="ghost"
                                    className={classnames(
                                        'flex gap-2 items-center hover:bg-success hover:text-success',
                                        isRelevant === true && 'bg-success text-success'
                                    )}
                                    onClick={() => {
                                        if (isRelevant === false) {
                                            handleRelevantChange(true);
                                        }
                                    }}
                                >
                                    <ThumbsUpIcon /> {t('listOfServices.positionCard.relevant')}
                                </Button>

                                {mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR && (
                                    <Button
                                        disabled={readOnly || loading}
                                        variant="ghost"
                                        className={classnames(
                                            'flex gap-2 items-center hover:bg-error hover:text-error',
                                            isRelevant === false && 'bg-error text-error'
                                        )}
                                        onClick={() => {
                                            if (isRelevant === true) {
                                                handleRelevantChange(false);
                                            }
                                        }}
                                    >
                                        <ThumbsDownIcon /> {t('listOfServices.positionCard.notRelevant')}
                                    </Button>
                                )}
                            </div>

                            {mode === LIST_OF_SERVICE_ASSISTANCE_MODES.EDITOR && isRelevant === true && (
                                <div className="flex gap-3 items-center">
                                    <label className="text-primary font-medium text-sm">
                                        {t('listOfServices.positionCard.include')}
                                    </label>
                                    <Switch value={isIncluded} onValueChange={onIncludedChange} />
                                </div>
                            )}
                        </div>

                        {isRelevant === false && <NotRelevantBanner reason={reason} />}

                        {isRelevant === true && isIncluded === false && (
                            <div className="text-sm text-primary bg-secondary rounded px-5 py-4">
                                {t('listOfServices.positionCard.notIncluded')}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default PositionAssistanceCard;
