import * as React from 'react';
import { useEffect, useState } from 'react';
import Canvas from '../../core_updated/components/Canvas';
import { withIcon } from '../../core_updated/components/Icon';
import { faArrowPointer, faArrowsUpDownLeftRight, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { mergeRefs } from '../../core_updated/utils/mergeRefs';
import { useDebounce } from '../../core/utils/hooks/useDebounce';
import { usePrevious } from '../../core_updated/utils/usePrevious';

const SelectIcon = withIcon(faArrowPointer);
const MoveIcon = withIcon(faArrowsUpDownLeftRight);
const ZoomInIcon = withIcon(faPlus);
const ZoomOutIcon = withIcon(faMinus);

const AssistanceCanvas = ({ children, artboardWidth, artboardHeight, viewportRef }: any) => {
    const internalViewportRef = React.useRef(null);

    const [zoom, setZoom] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [mode, setMode] = useState('select');
    const [canDrag, setCanDrag] = useState(false);

    const maxZoom = 2;
    const minZoom = 0.5;
    const zoomFactor = 0.1;

    const spacing = 100;

    /* Utils */

    const getDimensions = (el) => {
        const rect = el?.getBoundingClientRect();
        return {
            width: rect?.width || 0,
            height: rect?.height || 0,
            scrollWidth: el?.scrollWidth || 0,
            scrollHeight: el?.scrollHeight || 0,
        };
    };

    const zoomToPointInArtboard = (newZoom, point) => {
        const { width, height } = getDimensions(internalViewportRef.current);

        const spacingX = width - spacing / 2;
        const spacingY = height - spacing / 2;

        // min offset means cursor most left of artboard
        // max offset means cursor most right of artboard
        const cursorInArtboard = {
            x: Math.min(Math.max(point.x - spacingX, 0), artboardWidth * zoom),
            y: Math.min(Math.max(point.y - spacingY, 0), artboardHeight * zoom),
        };

        const nextCursorInArtboard = {
            x: cursorInArtboard.x * (newZoom / zoom),
            y: cursorInArtboard.y * (newZoom / zoom),
        };

        const offsetX = nextCursorInArtboard.x - cursorInArtboard.x;
        const offsetY = nextCursorInArtboard.y - cursorInArtboard.y;

        setZoom(newZoom);
        setPosition({
            x: position.x + offsetX,
            y: position.y + offsetY,
        });
    };

    const zoomInOut = (factor) => {
        // Zoom in or out while keeping the center of the svg in the center of the viewport
        const { width, height } = getDimensions(internalViewportRef.current);
        const newZoom = Math.max(minZoom, Math.min(maxZoom, zoom + factor));

        // point = center of viewport
        zoomToPointInArtboard(newZoom, { x: position.x + width / 2, y: position.y + height / 2 });
    };

    const zoomToFit = (goToTop = true) => {
        const { width, height, scrollWidth } = getDimensions(internalViewportRef.current);

        const calculatedZoom = Math.max(minZoom, Math.min(maxZoom, (width - spacing) / artboardWidth));

        setZoom(calculatedZoom);

        setPosition({
            x: width - spacing,
            y: goToTop ? height - spacing : position.y,
        });
    };

    /* Event handlers */

    const handleKeyDown = (e) => {
        if (e.key === ' ' && !isInputFieldFocused()) {
            e.preventDefault();
            e.stopPropagation();
            setCanDrag(true);
        } else if (e.key === '0' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            zoomToFit();
        } else if (e.key === '+' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            zoomInOut(zoomFactor);
        } else if (e.key === '-' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            zoomInOut(-zoomFactor);
        }
    };

    const handleKeyUp = (e) => {
        if (e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();
            setCanDrag(false);
        }
    };

    const isInputFieldFocused = () => {
        // Check if an input field is focused, if so don't allow spacebar to enable drag
        const activeElement = document.activeElement as HTMLElement;
        return (
            activeElement instanceof HTMLInputElement ||
            activeElement instanceof HTMLTextAreaElement ||
            activeElement.isContentEditable
        );
    };

    const debouncedZoomToFit = useDebounce(zoomToFit, 300);
    const prevDimensions = usePrevious({ width: artboardWidth, height: artboardHeight });

    const handleResize = () => {
        if (artboardWidth && artboardHeight) {
            if (!prevDimensions?.width || !prevDimensions?.height) {
                // initial load can directly zoom to fit
                zoomToFit();
            } else {
                // if e.g. window is resized only update after 300ms of no more resize
                debouncedZoomToFit(false);
            }
        }
    };

    /* Effects */

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyUp, handleKeyDown]);

    return (
        <Canvas.Root
            onResize={handleResize}
            position={position}
            onPositionChange={setPosition}
            zoom={zoom}
            onZoomChange={setZoom}
        >
            <Canvas.Viewport
                spacing={spacing}
                isDraggable={canDrag || mode === 'move'}
                ref={mergeRefs(internalViewportRef, viewportRef)}
                className={artboardWidth && artboardHeight ? '' : 'overflow-hidden'}
            >
                <Canvas.Artboard width={artboardWidth} height={artboardHeight}>
                    {children}
                </Canvas.Artboard>
            </Canvas.Viewport>

            <Canvas.Controls>
                <Canvas.ControlButton active={mode === 'select'} onClick={() => setMode('select')}>
                    <SelectIcon />
                </Canvas.ControlButton>
                <Canvas.ControlButton active={mode === 'move'} onClick={() => setMode('move')}>
                    <MoveIcon />
                </Canvas.ControlButton>

                <div className="border-r border-solid border-primary h-8" />

                <Canvas.ControlButton onClick={() => zoomInOut(zoomFactor)} disabled={zoom >= maxZoom}>
                    <ZoomInIcon />
                </Canvas.ControlButton>

                <Canvas.ControlButton className="px-2 h-8 w-auto text-xs font-medium" onClick={() => zoomToFit()}>
                    {Math.round(zoom * 100)}%
                </Canvas.ControlButton>

                <Canvas.ControlButton onClick={() => zoomInOut(-zoomFactor)} disabled={zoom <= minZoom}>
                    <ZoomOutIcon />
                </Canvas.ControlButton>
            </Canvas.Controls>
        </Canvas.Root>
    );
};

export default AssistanceCanvas;
