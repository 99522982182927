import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAssistanceContext } from './AssistanceContext';
import AssistanceCanvas from '../../../assistance/components/AssistanceCanvas';
import { ImageMap } from '../../../assistance/containers/ImageMap';
import SplitPanes, { Pane } from '../../../core/containers/SplitPanes';
import { useTranslation } from 'react-i18next';
import classnames from '../../../core_updated/utils/classnames';
import { withIcon } from '../../../core_updated/components/Icon';
import {
    faChevronDown,
    faChevronUp,
    faEnvelope,
    faFile,
    faPaperclip,
    faPaperPlaneAlt,
    faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import Frame from '../../../core/components/Frame';
import DOMPurify from 'dompurify';
import {
    EmailAttachment as IEmailAttachment,
    File as IFile,
    PURIFY_CONFIG,
} from '../../../assistance/containers/EmailPreview';
import fontsStyle from '!!raw-loader!../../../../../stylesheets/fonts.css';
import contentStyle from '!!raw-loader!../../../assistance/containers/EmailPreview/content.css';
import i18n from 'i18next';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';
import StringField, { StringFieldProps } from '../../../core_updated/components/Fields/StringField';
import TextField from '../../../core_updated/components/Fields/TextField';
import Button from '../../../core_updated/components/Button';
import Checkbox from '../../../core/components/Checkbox';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROCESSING_RECORD_EMAILS, SEND_ASSISTANCE_EMAIL } from '../../../orders/queries';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../../App';
import { Controller, useForm } from 'react-hook-form';
import Loader, { LoaderLine } from '../../../core/components/Loader';
import { Slot } from '@radix-ui/react-slot';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import Field from '../../../core_updated/components/Fields/Field';
import ChipInput, { SimpleChipInput } from '../../../core/containers/ChipInput';
import TagsField from '../../../core_updated/components/Fields/TagsField';
import Tooltip from '../../../core/components/Tooltip';
import WysiwygField from './WysiwygField';

const FileIcon = withIcon(faFile);
const CloseIcon = withIcon(faTimes);
const AttachmentIcon = withIcon(faPaperclip);
const SendIcon = withIcon(faPaperPlaneAlt);
const EmailIcon = withIcon(faEnvelope);
const ChevronUpIcon = withIcon(faChevronUp);
const ChevronDownIcon = withIcon(faChevronDown);

interface DocumentEmailAddress {
    name: string;
    email: string;
}

export interface DocumentEmailAttachment {
    url?: string;
    name?: string;
}

interface DocumentEmail {
    sender?: DocumentEmailAddress;
    receivers: DocumentEmailAddress[];
    cc?: DocumentEmailAddress[];
    bcc?: DocumentEmailAddress[];
    subject: string;
    createdAt?: Date;
    body?: string;
    mimetype?: string;
    attachments?: DocumentEmailAttachment[];
}

export const useDocumentEmailBody = (email: DocumentEmail) => {
    const attachmentsWithUrl = (email?.attachments || []).filter((attachment) => attachment?.url);
    const emailContent = DOMPurify.sanitize(email?.body, PURIFY_CONFIG) || '';

    return {
        content: emailContent,
        attachments: attachmentsWithUrl,
    };
};

const MAX_PREVIEW_RECEIVERS = 9;

const adjustEmail = (email: any): DocumentEmail => {
    // somehow in the backend the cc and bcc are stored as carbonCopy and blindCarbonCopy

    if (!email) return;

    return {
        ...email,
        cc: email.carbonCopy,
        bcc: email.blindCarbonCopy,
    };
};

const EmailHeader = ({ email }: { email: DocumentEmail }) => {
    const { t } = useTranslation('assistance');

    const [isCollapsed, setIsCollapsed] = useState(true);
    const allReceivers = email.receivers.concat(email.cc || []).concat(email.bcc || []);

    const hideButton = (
        <Tooltip content={t('emailsView.email.hideDetails')} placement="top">
            <button
                className="text-sm text-secondary px-0.5 hover:text-primary transition-colors ml-1 translate-y-px"
                onClick={() => setIsCollapsed(true)}
            >
                <ChevronUpIcon />
            </button>
        </Tooltip>
    );

    return (
        <div className="flex gap-6 justify-between items-start">
            <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-1 items-center">
                    <span className="font-medium text-primary">{email.sender.name}</span>
                    <span className="text-secondary text-sm">&lt;{email.sender.email}&gt;</span>

                    <span className="block text-secondary text-sm ml-auto">
                        {new Date(email.createdAt).toLocaleString(i18n.language)}
                    </span>
                </div>
                <div className="text-secondary text-sm flex flex-col gap-0 leading-relaxed">
                    {isCollapsed ? (
                        <span>
                            {t('emailsView.email.to')}:{' '}
                            {allReceivers
                                .slice(0, MAX_PREVIEW_RECEIVERS)
                                .map((r) => r.email.split('@')[0])
                                .join(', ')}
                            {allReceivers.length > MAX_PREVIEW_RECEIVERS &&
                                ` ${t('emailsView.email.moreReceivers', {
                                    count: allReceivers.length - MAX_PREVIEW_RECEIVERS,
                                })}`}
                            <Tooltip content={t('emailsView.email.showDetails')} placement="top">
                                <button
                                    className="text-sm text-secondary px-0.5 hover:text-primary transition-colors ml-1"
                                    onClick={() => setIsCollapsed(false)}
                                >
                                    <ChevronDownIcon />
                                </button>
                            </Tooltip>
                        </span>
                    ) : (
                        <>
                            <span className="flex gap-1">
                                <span className="flex-none w-9">{t('emailsView.email.to')}:</span>
                                <span>
                                    {email.receivers.map((r) => r.email).join(', ')}
                                    {!email.cc && !email.bcc && hideButton}
                                </span>
                            </span>
                            {email.cc && (
                                <span className="flex gap-1">
                                    <span className="flex-none w-9">{t('emailsView.email.cc')}:</span>
                                    <span>
                                        {email.cc.map((r) => r.email).join(', ')}
                                        {!email.bcc && hideButton}
                                    </span>
                                </span>
                            )}
                            {email.bcc && (
                                <span className="flex gap-1">
                                    <span className="flex-none w-9">{t('emailsView.email.bcc')}:</span>
                                    <span>
                                        {email.bcc.map((r) => r.email).join(', ')}
                                        {hideButton}
                                    </span>
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="flex gap-2 items-center text-secondary text-sm">
                {email.attachments.length > 0 && (
                    <span className="text-primary">
                        <AttachmentIcon />
                    </span>
                )}
            </div>
        </div>
    );
};

const EmailContent = ({
    subject,
    content,
    attachments,
}: {
    subject: string;
    content: string;
    attachments: IEmailAttachment[];
}) => {
    const isDarkMode = !!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY);

    return (
        <div className="rounded-xl flex flex-col gap-5 p-7 border border-primary border-solid bg-primary">
            <div className="font-medium text-primary">{subject}</div>
            <div className="flex flex-col gap-4 leading-relaxed">
                <Frame>
                    <style>
                        {fontsStyle.replaceAll('../', '/')}
                        {contentStyle}
                    </style>
                    <div
                        className={classnames('email__html', isDarkMode && 'email__html--dark-mode')}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </Frame>
            </div>

            {attachments.length > 0 &&
                attachments.map((attachment, index) => <EmailAttachment key={index} attachment={attachment} />)}
        </div>
    );
};

const EmailAttachment = ({ attachment }: { attachment: IEmailAttachment }) => {
    return (
        <a
            className="flex gap-3 items-center bg-secondary !text-primary px-4 py-3 border border-primary border-solid rounded-md hover:bg-tertiary hover:border-tertiary cursor-pointer transition-colors"
            target="_blank"
            href={attachment.url}
        >
            <FileIcon />
            <span className="text-primary">{attachment.name}</span>
        </a>
    );
};

const EmailAvatar = ({ emailAddress }: { emailAddress: DocumentEmailAddress }) => {
    const initials = emailAddress.name
        ? emailAddress.name
              .split(' ')
              .map((n) => n[0])
              .join('')
        : emailAddress.email[0];
    return (
        <div className="flex flex-none border border-solid border-primary items-center justify-center w-8 h-8 bg-primary rounded-full text-primary font-medium text-sm -mt-1.5">
            {initials}
        </div>
    );
};

const Email = ({ email }: { email: DocumentEmail }) => {
    const { content, attachments } = useDocumentEmailBody(email);
    return (
        <div className="flex gap-3">
            <EmailAvatar emailAddress={email.sender} />

            <div className="flex flex-col gap-4 flex-1">
                <EmailHeader email={email} />
                <EmailContent subject={email.subject} content={content} attachments={attachments} />
            </div>
        </div>
    );
};

const EmailLoader = () => {
    return (
        <div className="flex gap-3">
            <div className="flex flex-none border border-solid border-primary items-center justify-center w-8 h-8 bg-primary rounded-full text-primary font-medium text-sm -mt-1.5" />

            <div className="flex flex-col gap-4 flex-1">
                <div className="flex flex-col gap-4 flex-1">
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-1 items-center">
                            <span className="font-medium text-primary w-32">
                                <LoaderLine />
                            </span>
                            <span className="text-secondary text-sm w-32">
                                <LoaderLine />
                            </span>
                        </div>
                    </div>

                    <div className="rounded-xl flex flex-col gap-5 p-7 border border-primary border-solid bg-primary">
                        <Loader className="p-0 m-0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const EmailFormField = ({ className, label, required = false, children, error, ...props }: any) => {
    return (
        <div className={classnames('flex flex-col gap-3', className)} {...props}>
            <label className="text-xs text-secondary w-36 flex-none flex justify-start gap-1">
                <span className="truncate">{label}</span> {required && <span className="text-error">*</span>}
            </label>
            <div className="flex gap-1 flex-1 min-w-0 flex-col">
                <Slot
                    className={classnames(
                        'hover:!border-confidence-high focus-within:border-confidence-high focus-within:outline-surface-brand',
                        error && 'border-confidence-low'
                    )}
                >
                    {children}
                </Slot>
            </div>
            {error?.message && <span className="text-confidence-low text-xs">{error.message}</span>}
        </div>
    );
};

const EmailFormAttachment = ({
    attachment,
    checked,
    onCheckedChange,
}: {
    attachment: IEmailAttachment;
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
}) => {
    return (
        <div
            className="flex gap-3 items-center text-primary px-4 py-3 border border-primary border-solid rounded-md hover:bg-secondary cursor-pointer transition-colors"
            onClick={() => onCheckedChange(!checked)}
        >
            <FileIcon />
            <span className="text-primary">{attachment.name}</span>

            <div className="ml-auto">
                <Checkbox checked={checked} readOnly className="pointer-events-none" />
            </div>
        </div>
    );
};

const EmailForm = ({
    originalEmail,
    sourceFile,
    onSubmit,
    onCancel,
}: {
    originalEmail?: DocumentEmail;
    sourceFile?: IFile;
    onSubmit?: (values: DocumentEmail) => void;
    onCancel?: () => void;
}) => {
    const toRef = React.useRef<HTMLInputElement>(null);
    const messageRef = React.useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation('assistance');

    const { user } = useApplicationContext();
    const userEmailAddress = { name: `${user.firstName} ${user.lastName}`, email: user.email };

    const [isCcVisible, setIsCcVisible] = useState(false);
    const [isBccVisible, setIsBccVisible] = useState(false);

    const form = useForm({
        defaultValues: {
            to: '',
            cc: '',
            bcc: '',
            subject: '',
            message: '',
            attachmentIndices: [],
        },
        mode: 'onBlur',
    });
    const formErrors = form.formState.errors;

    useEffect(() => {
        if (originalEmail) {
            form.reset({
                to: originalEmail?.receivers.map((receiver) => receiver.email).join(', '),
                subject: `Re: ${originalEmail?.subject}`,
                message: '',
                attachmentIndices: [],
            });
            messageRef.current?.focus();
        } else {
            toRef.current?.focus();
        }
    }, [originalEmail]);

    const splitEmailAddresses = (emailString: string) =>
        emailString
            .split(',')
            .filter(Boolean)
            .map((email: string) => ({
                name: originalEmail && originalEmail?.sender.email === email ? originalEmail.sender.name : '',
                email: email,
            }));

    const containsHtml = (str: string) => /<[^>]*>/.test(str);

    const handleSubmit = (values: any) =>
        onSubmit?.({
            receivers: splitEmailAddresses(values.to),
            cc: splitEmailAddresses(values.cc),
            bcc: splitEmailAddresses(values.bcc),
            subject: values.subject,
            body: values.message,
            mimetype: containsHtml(values.message) ? 'text/html' : 'text/plain',
            attachments: values.attachmentIndices.map((i) => {
                const attachment = attachments[i];
                return {
                    name: attachment.name,
                    url: attachment.url,
                };
            }),
        });

    const getValidateEmaiAddressList = (key: string) => (value: string) => {
        // split by , and then check each email
        const emails = value
            .split(',')
            .map((e) => e.trim())
            .filter(Boolean);
        if (emails.some((e) => !/^\S+@\S+$/i.test(e))) {
            return t(`emailsView.emailForm.${key}Invalid`);
        }
    };

    const attachments = sourceFile ? [sourceFile] : [];

    const attachmentIndices = form.watch('attachmentIndices');

    return (
        <div className="flex gap-3">
            <EmailAvatar emailAddress={userEmailAddress} />

            <div className="flex flex-col gap-4 flex-1">
                <div className="flex flex-col gap-2">
                    <div className="flex gap-1 items-center">
                        <span className="font-medium text-primary">{userEmailAddress.name}</span>
                        <span className="text-secondary text-sm">&lt;{userEmailAddress.email}&gt;</span>
                    </div>
                </div>

                <form
                    className="rounded-xl flex flex-col gap-5 p-7 border border-primary border-solid bg-primary"
                    onSubmit={form.handleSubmit(handleSubmit)}
                    noValidate
                >
                    <Controller
                        name="to"
                        control={form.control}
                        rules={{
                            required: t('emailsView.emailForm.toRequired'),
                            validate: getValidateEmaiAddressList('to'),
                        }}
                        render={({ field }) => (
                            <EmailFormField
                                label={t('emailsView.emailForm.toLabel')}
                                required={true}
                                error={formErrors.to}
                            >
                                <TagsField
                                    required={true}
                                    inputRef={toRef}
                                    {...field}
                                    onValueChange={field.onChange}
                                    controls={
                                        <div className="flex-none flex items-center gap-0.5">
                                            {!isCcVisible && (
                                                <Field.ControlButton
                                                    active={isCcVisible}
                                                    className="px-1.5 box-border"
                                                    onClick={() => setIsCcVisible(true)}
                                                >
                                                    cc
                                                </Field.ControlButton>
                                            )}
                                            {!isBccVisible && (
                                                <Field.ControlButton
                                                    active={isBccVisible}
                                                    className="px-1.5 box-border"
                                                    onClick={() => setIsBccVisible(true)}
                                                >
                                                    bcc
                                                </Field.ControlButton>
                                            )}
                                        </div>
                                    }
                                />
                            </EmailFormField>
                        )}
                    />

                    <Controller
                        name="cc"
                        control={form.control}
                        rules={{ validate: getValidateEmaiAddressList('cc') }}
                        render={({ field }) =>
                            isCcVisible && (
                                <EmailFormField label={t('emailsView.emailForm.ccLabel')} error={formErrors.cc}>
                                    <TagsField {...field} onValueChange={field.onChange} />
                                </EmailFormField>
                            )
                        }
                    />

                    <Controller
                        name="bcc"
                        control={form.control}
                        rules={{ validate: getValidateEmaiAddressList('bcc') }}
                        render={({ field }) =>
                            isBccVisible && (
                                <EmailFormField label={t('emailsView.emailForm.bccLabel')} error={formErrors.bcc}>
                                    <TagsField {...field} onValueChange={field.onChange} />
                                </EmailFormField>
                            )
                        }
                    />

                    <Controller
                        name="subject"
                        control={form.control}
                        rules={{ required: t('emailsView.emailForm.subjectRequired') }}
                        render={({ field }) => (
                            <EmailFormField
                                label={t('emailsView.emailForm.subjectLabel')}
                                required={true}
                                error={formErrors.subject}
                            >
                                <StringField required={true} {...field} />
                            </EmailFormField>
                        )}
                    />

                    <Controller
                        name="message"
                        control={form.control}
                        rules={{ required: t('emailsView.emailForm.messageRequired') }}
                        render={({ field }) => (
                            <EmailFormField
                                label={t('emailsView.emailForm.messageLabel')}
                                required={true}
                                error={formErrors.message}
                            >
                                <WysiwygField required={true} {...field} />
                            </EmailFormField>
                        )}
                    />

                    {attachments.length > 0 ? (
                        <EmailFormField label="Attached Files" error={formErrors.attachmentIndices}>
                            <div>
                                {attachments.map((a, index) => (
                                    <EmailFormAttachment
                                        key={a.url}
                                        attachment={a}
                                        checked={attachmentIndices?.includes(index)}
                                        onCheckedChange={(checked) =>
                                            form.setValue(
                                                'attachmentIndices',
                                                checked
                                                    ? [...attachmentIndices, index]
                                                    : attachmentIndices.filter((i) => i !== index)
                                            )
                                        }
                                    />
                                ))}
                            </div>
                        </EmailFormField>
                    ) : null}

                    <div className="flex gap-2 justify-end">
                        <Button type="button" onClick={onCancel}>
                            {t('emailsView.emailForm.cancelButton')}
                        </Button>
                        <Button
                            className="border-transparent bg-brand-hover hover:bg-brand-default active:bg-brand-active text-inverted flex items-center gap-2"
                            type="submit"
                        >
                            <SendIcon /> {t('emailsView.emailForm.sendButton')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const EmailsView = React.memo(() => {
    const { t } = useTranslation('assistance');

    const [isFormVisible, setIsFormVisible] = useState(false);

    const { record } = useAssistanceContext();
    const {
        data: emailsData,
        loading: emailsLoading,
        refetch: emailsRefetch,
    } = useQuery(GET_PROCESSING_RECORD_EMAILS, {
        variables: {
            recordId: record?.id,
        },
        skip: !record?.id,
    });
    const emails: DocumentEmail[] = emailsData?.orderDocumentEmails?.sent || [];

    useEffect(() => {
        if (record?.id && !emailsLoading && emails.length === 0) {
            setIsFormVisible(true);
        }
    }, [record?.id, emailsLoading, emails]);

    const [sendAssistanceEmail] = useMutation(SEND_ASSISTANCE_EMAIL);

    const handleSubmit = async (email: DocumentEmail) => {
        await sendAssistanceEmail({
            variables: {
                recordId: record.id,
                email: email,
            },
        });
        return await emailsRefetch();
    };

    return (
        <SplitPanes initialWidths={[66, 34]} initialHeights={[66, 34]}>
            <Pane className="bg-secondary">
                <AssistanceImageMap />
            </Pane>
            <Pane>
                <aside className="flex flex-col flex-auto min-h-0 overflow-auto bg-secondary">
                    <div className="flex flex-col w-full relative">
                        <div className="flex justify-between gap-4 items-center sticky top-0 z-20 bg-primary p-6 border-b border-solid border-primary text-primary">
                            <h3 className="font-medium">{t('emailsView.title')}</h3>
                        </div>
                        <div className="px-6 py-8 bg-secondary flex flex-col gap-8">
                            {(!record?.id || emailsLoading) && <EmailLoader />}

                            {emails.map((email, index) => (
                                <Email key={index} email={adjustEmail(email)} />
                            ))}

                            {isFormVisible ? (
                                <EmailForm
                                    originalEmail={emails?.length > 0 ? emails[0] : undefined}
                                    sourceFile={emailsData?.orderDocumentEmails?.sourceFile}
                                    onSubmit={async (email) => {
                                        await handleSubmit(email);
                                        setIsFormVisible(false);
                                    }}
                                    onCancel={() => setIsFormVisible(false)}
                                />
                            ) : (
                                <div className="flex justify-end">
                                    <Button
                                        onClick={() => setIsFormVisible(true)}
                                        className="flex gap-2 items-center"
                                        disabled={!record?.id || emailsLoading}
                                    >
                                        <EmailIcon />
                                        {t('emailsView.newEmailButton')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </aside>
            </Pane>
        </SplitPanes>
    );
});

const AssistanceImageMap = (props: any) => {
    const { ocr } = useAssistanceContext();

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const handleImageLoad = (evt) => {
        const { width, height } = evt.currentTarget;
        setImageDimensions({ width, height });
    };

    const [imageSrc, setImageSrc] = useState(ocr?.processedFile.url);
    useEffect(() => {
        setImageSrc(ocr?.processedFile.url);
    }, [ocr?.processedFile.url]);

    return (
        <AssistanceCanvas artboardWidth={imageDimensions.width} artboardHeight={imageDimensions.height}>
            <ImageMap src={imageSrc} numPages={ocr?.pagesCount} onLoad={handleImageLoad} />
        </AssistanceCanvas>
    );
};

export default EmailsView;
