import { isEmpty as isEmptyObject } from 'lodash';
import { ORDER_EXTRACT_TEXT } from '../../../orders/queries';
import { documentTypeName } from '../../../orders/pages/OrderAssistance/utils';
import { FormField } from './useDocumentConfig';
import { MEDIUM_CONFIDENCE_THRESHOLD } from './AssistanceField';
import { convertPythonDateTimeFormat } from '../../../utils/dates';
import { buildMasterdataFieldPropsFromConfig } from '../../../masterdata/containers/MasterdataBoundField';
import { useAssistanceContext } from './AssistanceContext';
import { getDocumentMatchingKey, getDocumentMatchingSeverity } from './useFieldExplanations';

const valueTypeToFieldTypeMap = {
    string: 'StringField',
    integer: 'IntegerField',
    float: 'DecimalField',
    decimal: 'DecimalField',
    boolean: 'BooleanField',
    date: 'DateField',
    datetime: 'DateField',
    dict: 'JSONStringField',
    list: 'ListField',
};

export const fieldGroupSubfieldNames = {
    AddressField: [
        'addressId',
        'addressId2',
        'name',
        'companyName',
        'streetAndNr',
        'postcode',
        'city',
        'country',
        'email',
        'phone',
        'misc',
    ],
    CustomerField: ['customerNumber', 'name', 'address', 'internalNote'],
    ContactField: ['contactId', 'name', 'email', 'phone'],
};

export const fieldToFormFieldConfig = (
    field,
    { client, recordId, documentConfig, documentConfiguration, document, handlers }
) => {
    const config = {
        fieldType: valueTypeToFieldTypeMap[field.valueType],
        fieldProps: {
            required: field.isRequired,
            hidden: false,
        },
        inputProps: {
            required: field.isRequired,
        },
        onReselect: null,
    };

    if (field.performLookups) {
        config.fieldType = 'AutocompleteField';
        config.onReselect =
            client && recordId && getInterceptedOnReselect(client, recordId, documentConfiguration?.EXTRACT_TEXT);
        config.fieldProps = {
            ...config.fieldProps,
            ...buildMasterdataFieldPropsFromConfig(client, field, document, handlers),
            ...{
                // Note: carry through fieldType for hard-wired composite fields (e.g. CustomerField), can be removed
                // after refactoring hard-wired composite fields to dynamic composite
                fieldType: config.fieldType,
                forceSelection: !field.allowInvalidIds, // TODO remove after rename to allowNonLookupValues (see buildMasterdataFieldPropsFromConfig)
            },
        };
    }

    if (field.valueType === 'date' || field.valueType === 'datetime') {
        const dateFormat =
            documentConfig && documentConfig?.dateFormat && convertPythonDateTimeFormat(documentConfig?.dateFormat);
        if (dateFormat) {
            config.fieldProps['format'] = dateFormat;
        }
    }

    if (field.valueType === 'decimal' || field.valueType === 'float') {
        const decimalSeparator = documentConfig && documentConfig?.decimalSeparator;
        if (decimalSeparator) {
            config.fieldProps['decimalSeparator'] = decimalSeparator;
        }
    }

    return config;
};

export function fieldConfigToLegacyConfigFormat(
    fieldConfigs,
    configs: {},
    client,
    recordId,
    documentConfig,
    fieldNameToGroupTypeMap
) {
    const { handlers, document, documentConfiguration } = useAssistanceContext();

    if (fieldConfigs && document) {
        const baseFields = fieldConfigs?.filter((field) => !field.groupName) || [];
        baseFields.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });

        const groupFields = fieldConfigs?.filter((field) => field.groupName) || [];
        groupFields.forEach((field) => {
            // make sure we have a config for the group field
            configs[field.groupName] = configs[field.groupName] || {};
            configs[field.groupName]['groupFieldProps'] = configs[field.groupName]['groupFieldProps'] || {};

            configs[field.groupName] = configs[field.groupName] || {};
            configs[field.groupName]['groupInputProps'] = configs[field.groupName]['groupInputProps'] || {};

            // get the actual data
            const config = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
            configs[field.groupName]['groupFieldProps'][field.name] = config?.['fieldProps'] || {};
            configs[field.groupName]['groupInputProps'][field.name] = config?.['inputProps'] || {};
        });

        // This is really ugly but what the heck: we need to make sure that we hide subfields from group fields that are not configured
        Object.entries(fieldNameToGroupTypeMap)
            .filter(([fieldName]) => Object.keys(configs).includes(fieldName))
            .map(([fieldName, groupType]: any) => {
                const subfieldNames = fieldGroupSubfieldNames[groupType];
                subfieldNames.forEach((subfieldName) => {
                    if (!configs[fieldName]?.['groupFieldProps']?.[subfieldName]) {
                        if (!configs[fieldName]?.['groupFieldProps']) {
                            configs[fieldName]['groupFieldProps'] = {};
                        }

                        configs[fieldName]['groupFieldProps'][subfieldName] = {
                            hidden: true,
                        };
                    }
                });
            });
    }
}

export const extractText = (client, recordId, bbox, pageIndex, query, documentTypeName) => {
    return client
        .query({
            query: query,
            variables: {
                recordId,
                bbox,
                pageIndex,
            },
            fetchPolicy: 'no-cache',
        })
        .then((res) => res?.data?.[documentTypeName + 'ExtractText']?.text || '')
        .catch((err) => {
            console.error(err);
            return '';
        });
};

export const getInterceptedOnReselect =
    (client, recordId, extractTextQuery = ORDER_EXTRACT_TEXT) =>
    ({ fieldName, bbox, pageIndex, itemIndex }) => {
        // This method extracts text within the selected bbox and triggers a simulated onChange event on
        // the AutocompleteField. This way we won't trigger onReselect with an invalid value.
        return extractText(client, recordId, bbox, pageIndex, extractTextQuery, documentTypeName).then((text) => {
            const fieldNode: any = document.querySelector(`.item-${itemIndex} .field--${fieldName} input`);
            if (!fieldNode) return;

            fieldNode?.focus();

            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            ).set;
            nativeInputValueSetter.call(fieldNode, text);

            const inputEvent = new Event('input', { bubbles: true });
            fieldNode.dispatchEvent(inputEvent);
        });
    };

export const fieldIsGreen = (
    field: Pick<FormField, 'config' | 'value' | 'data'>,
    { includeMatching = true } = {}
): boolean => {
    // TODO: This whole TROX isComposite sucks)
    if (!(field.config?.valueType || field.data?.isComposite)) return false;

    if (
        field.data?.confidenceExplanation?.translationKey === 'explanation.historical_matches' &&
        field.data?.predictionConfidence >= MEDIUM_CONFIDENCE_THRESHOLD
    )
        return true;

    return false;
};

export const fieldIsYellow = (
    field: Pick<FormField, 'config' | 'value' | 'data'>,
    { includeMatching = true } = {}
): boolean => {
    // TODO: This whole TROX isComposite sucks)
    if (!(field.config?.valueType || field.data?.isComposite)) return false;

    const isEmpty = isFieldValueEmpty(field.value);

    // OC Matching (yellow)
    if (includeMatching && field.data?.confidenceExplanation?.explanationDetails?.document_matching_result) {
        const documentMatchingKey = getDocumentMatchingKey(
            field.data?.confidenceExplanation?.explanationDetails,
            field.config?.isRequired,
            isEmpty
        );
        const severity = getDocumentMatchingSeverity(documentMatchingKey);

        if (severity === 'warning') return true;
    }

    // if optional and empty it's never yellow
    if (!field.config?.isRequired && isEmpty) return false;

    // if it doesn't block automation it's never yellow
    if (field.config?.ignoreConfidence) return false;

    // if required and blocks automation and has value and is below medium confidence
    if (
        !field.config?.ignoreConfidence &&
        !isEmpty &&
        // Note: this logic was changed to have less red fields
        // field.data?.predictionConfidence < MEDIUM_CONFIDENCE_THRESHOLD &&
        // field.data?.predictionConfidence >= LOW_CONFIDENCE_THRESHOLD
        field.data?.predictionConfidence < MEDIUM_CONFIDENCE_THRESHOLD
    )
        return true;

    return false;
};

export const fieldIsRed = (
    field: Pick<FormField, 'config' | 'value' | 'data'>,
    { includeMatching = true } = {}
): boolean => {
    // TODO: This whole TROX isComposite sucks)
    if (!(field.config?.valueType || field.data?.isComposite)) return false;

    const isEmpty = isFieldValueEmpty(field.value);

    // OC Matching (red)
    if (includeMatching && field.data?.confidenceExplanation?.explanationDetails?.document_matching_result) {
        const documentMatchingKey = getDocumentMatchingKey(
            field.data?.confidenceExplanation?.explanationDetails,
            field.config?.isRequired,
            isEmpty
        );

        const severity = getDocumentMatchingSeverity(documentMatchingKey);

        if (severity === 'error') return true;
    }

    // if required and is empty
    if (field.config?.isRequired && isEmpty) return true;

    // if optional and empty it's never red
    if (!field.config?.isRequired && isEmpty) return false;

    // if required and blocks automation and has value and is below low confidence
    // Note: this logic was changed to have less red fields
    // if (
    //     field.config?.isRequired &&
    //     !field.config?.ignoreConfidence &&
    //     !isEmpty &&
    //     field.data?.predictionConfidence < LOW_CONFIDENCE_THRESHOLD
    // )
    //     return true;

    return false;
};

export const filterFastTrack = (formFieldGroups: FormField[][]): any => {
    let mediumConfidenceFieldsCount = 0;
    let lowConfidenceFieldsCount = 0;

    const isFieldIncomplete = (formField: FormField) => {
        const isFieldYellow = fieldIsYellow(formField);
        const isFieldRed = fieldIsRed(formField);

        if (isFieldYellow || isFieldRed) {
            if (isFieldRed) {
                lowConfidenceFieldsCount++;
            } else if (isFieldYellow) {
                mediumConfidenceFieldsCount++;
            }

            return true;
        }
    };

    return [
        // this way we are first iterating through all items so the counters are correct
        formFieldGroups.filter((formFields) => formFields.map(isFieldIncomplete).some(Boolean)),
        {
            medium: mediumConfidenceFieldsCount,
            low: lowConfidenceFieldsCount,
        },
    ];
};

export const isFieldValueEmpty = (value: any): boolean => {
    // Note "false" is a valid value
    if (typeof value === 'boolean') return false;

    return (
        value === null ||
        value === undefined ||
        value === '' ||
        // no need for deserialization here
        value === '[]' ||
        value === '{}' ||
        // in case we get other objects
        (typeof value === 'object' && isEmptyObject(value))
    );
};
