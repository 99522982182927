import * as React from 'react';
import { url } from '../../../../core/utils/link';
import { useContext } from 'react';

interface OverviewContextProviderProps {
    finished: boolean;
    testing: boolean;
    channelId: string;
    documentConfiguration: any;
}

interface OverviewContextProps extends OverviewContextProviderProps {
    finishedUrl: string;
    testingUrl: string;
    openUrl: string;
}

const OverviewContext = React.createContext<OverviewContextProps>({
    finished: false,
    testing: false,
    channelId: null,
    finishedUrl: '',
    testingUrl: '',
    openUrl: '',
    documentConfiguration: null,
});

export const OverviewContextProvider = ({
    children,
    channelId,
    documentConfiguration,
    ...props
}: OverviewContextProviderProps & { children: React.ReactNode; documentConfiguration: any }) => {
    const openUrl = channelId
        ? url(documentConfiguration.constants.CHANNEL_PATH, { channelId })
        : url(documentConfiguration.constants.OVERVIEW_PATH);

    const finishedUrl = channelId
        ? url(documentConfiguration.constants.CHANNEL_FINISHED_PATH, { channelId })
        : url(documentConfiguration.constants.OVERVIEW_FINISHED_PATH);

    const testingUrl = channelId
        ? url(documentConfiguration.constants.CHANNEL_TESTING_PATH, { channelId })
        : url(documentConfiguration.constants.OVERVIEW_TESTING_PATH);

    return (
        <OverviewContext.Provider
            value={{
                ...props,
                finishedUrl,
                testingUrl,
                openUrl,
                channelId,
                documentConfiguration,
            }}
        >
            {children}
        </OverviewContext.Provider>
    );
};

export const useOverviewContext = () => useContext(OverviewContext);
